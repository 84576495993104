import { createStore } from 'vuex'

const authHeaders = localStorage.getItem('olsp_auth_headers') ? JSON.parse(localStorage.getItem('olsp_auth_headers')) : {
  ['Authorization']: null,
  ['Device-Id']: null,
  ['Device-Type']: null,
  ['Token']: null
}

const tokenPayment = localStorage.getItem('lintas_token_payment') ? localStorage.getItem('lintas_token_payment') : null
const tagPageDisplay = localStorage.getItem('lintas_tag_display') ? localStorage.getItem('lintas_tag_display') : null

export default createStore({
  state: {
    authHeaders,
    tokenPayment,
    tagPageDisplay,
  },
  getters: {
    authHeaders: state => state.authHeaders,
    tokenPayment: state => state.tokenPayment,
    tagPageDisplay: state => state.tagPageDisplay,
  },
  mutations: {
    SET_TOKEN_PAYMENT(state, payload) {
      state.tokenPayment = payload
      localStorage.setItem('lintas_token_payment', payload)
    },
    REMOVE_TOKEN_PAYMENT(state) {
      state.tokenPayment = null
      localStorage.removeItem('lintas_token_payment')
    },

    SET_AUTH_HEADERS(state, payload) {
      state.authHeaders = payload
      localStorage.setItem('olsp_auth_headers', JSON.stringify(payload))
    },
    REMOVE_AUTH_HEADERS(state) {
      state.authHeaders = {
        ['Authorization']: null,
        ['Device-Id']: null,
        ['Device-Type']: null,
        ['Token']: null
      }
      localStorage.removeItem("olsp_auth_headers")
    },
    SET_TAG_DISPLAY(state, payload) {
      state.tokenPayment = payload
      localStorage.setItem('lintas_tag_display', payload)
    },
    REMOVE_TAG_DISPLAY(state) {
      state.tagPageDisplay = null
      localStorage.removeItem('lintas_tag_display')
    },
  },
  actions: {
    setTokenPayment({ commit }, payload) {
      commit('SET_TOKEN_PAYMENT', payload)
    },
    removeTokenPayment({ commit }) {
      commit('REMOVE_TOKEN_PAYMENT')
    },
    setAuthHeaders({ commit }, payload) {
      commit('SET_AUTH_HEADERS', payload)
    },
    removeAuthHeaders({ commit }) {
      commit('REMOVE_AUTH_HEADERS')
    },
    setTagPageDisplay({ commit }, payload) {
      commit('SET_TAG_DISPLAY', payload)
    },
    removeTagPageDisplay({ commit }) {
      commit('REMOVE_TAG_DISPLAY')
    },
  },
  modules: {
  }
})
